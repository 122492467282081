<script>
export default {
  data() {
    return {
      currentQueries: window.location.href.includes("?")
        ? window.location.href.substring(window.location.href.indexOf("?"))
        : "",
    };
  },
  created() {
    this.handleLogout();
  },
  methods: {
    handleLogout() {
      this.$cookies.keys().forEach((cookie) => this.$cookies.remove(cookie));
      // localStorage.removeItem("userData");
      window.location.href = `/login${this.currentQueries}`;
    },
  },
};
</script>

<style>
.bg-main {
  display: none;
}
</style>
